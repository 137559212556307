import { Button, Loading } from "@global";
import { Container, PageWidth } from "@util/standard";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { PageProps, Script, graphql, navigate } from "gatsby";
import React, { ChangeEvent, useEffect, useState } from "react";
import { colors, mediaQuery } from "@util/constants";
import { getCookieValue, isBrowser, setCookie, stubbedData } from "@util/helper";

import { GroupUserProgress } from "@components";
import Header from "@shared/header";
import { PageContext } from "@util/types";
import { Query } from "@graphql-types";
import styled from "styled-components";
import { toPlainText } from "@portabletext/react";
import { useCheckScreenSize } from "@util/hooks";
import { useGroupHooks } from "@util/groupHooks";
import { useSaveHoustonOnboarding } from "@state/logicHooks";
import { useStore } from "@state/store";

interface Props extends PageProps {
  pageContext: PageContext;
  data: Query;
}

const GroupDashboardTemplate = ({ pageContext, data }: Props) => {
  const steps =
    data?.sanityHouston &&
    data?.sanityHouston?.onboardingSteps?.map(step => {
      if (step?.content == null || step?.targetName == null) return;

      return {
        target: `#${step?.targetName}`,
        content: toPlainText(step?.content._rawBlockContent),
        disableBeacon: true,
        delayStart: step.delayStart ?? null,
      };
    });

  const { user, setWorkshopTabsTourState, setUser } = useStore();
  const {
    groups,
    sortedGroupUsers,
    loadGroupUsers,
    selectedGroup,
    sortingMethod,
    setSortingMethod,
    runTour,
    setRunTour,
    loading,
    refresh,
    loadGroupsArray,
    confirmHoustonOnboardingCompleted,
  } = useGroupHooks(steps);
  const { tabletDown } = useCheckScreenSize();

  const [emailList, setEmailList] = useState<string[]>([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [state, setState] = useState(0);
  const [tabIsExpanded, setTabIsExpanded] = useState(false);
  const [tourRunning, setTourRunning] = useState(false);

  const restartTour = () => {
    setStepIndex(0);
    setRunTour(true);
    setTourRunning(true);
  };

  const handleGroupSwitch = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    loadGroupUsers(event.target.value);
  };

  const handleSelectAll = () => {
    if (emailList.length === 0) {
      const allEmails = sortedGroupUsers?.map(user => user?.email) || [];
      setEmailList(allEmails);
    } else {
      setEmailList([]);
    }
  };

  const handleSort = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setSortingMethod(event.target.value);
  };

  const handleJoyrideCallback = data => {
    const { status, index, type, action } = data;
    setTourRunning(true);

    if ([EVENTS.STEP_AFTER].includes(type) && index === 3) {
      setRunTour(false);
      setEmailList(["test@test.com"]);
      setStepIndex(4);
      setTimeout(() => {
        setRunTour(true);
      }, 700);
    }

    if ([EVENTS.STEP_AFTER].includes(type) && index === 4) {
      setEmailList([]);
    }

    if ([EVENTS.STEP_AFTER].includes(type) && index === 9) {
      setRunTour(false);
      setTabIsExpanded(true);
      setTimeout(() => {
        setRunTour(true);
      }, 700);
    }

    if ([EVENTS.STEP_AFTER].includes(type) && index === 14) {
      console.log("here");

      setRunTour(false);
      setWorkshopTabsTourState(1);
      setTimeout(() => {
        setRunTour(true);
      }, 700);
    }

    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }

    if ([STATUS.FINISHED].includes(status)) {
      console.log("Tour Ended");
      setTabIsExpanded(false);
      setRunTour(false);
      setCookie(`tour-houston`, "true", 1000);
      confirmHoustonOnboardingCompleted();
      setTourRunning(false);
      loadGroupsArray().then(() => {
        refresh();
      });
    }
  };

  useEffect(() => {
    if (!isBrowser()) return;
    if (!user?.uid) {
      navigate("/");
    }
  }, [user]);

  console.log({ groups });

  return (
    <StyledDashboard id="houston-wrapper">
      <Script
        id="sib"
        dangerouslySetInnerHTML={{
          __html: ` (function(d, w, c) {
            w.SibConversationsID = '63be052fd44be91a1b24e842';
            w[c] = w[c] || function() {
                (w[c].q = w[c].q || []).push(arguments);
            };
            var s = d.createElement('script');
            s.async = true;
            s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
            if (d.head) d.head.appendChild(s);
        })(document, window, 'SibConversations');`,
        }}
      />
      <Header state={state} setState={setState} showLogo setRunTour={restartTour} />
      {tabletDown ? (
        <PageWidth>
          <p>Please visit Houston from a desktop or tablet</p>
        </PageWidth>
      ) : (
        <PageWidth flexDirection="column" width="90%" fullWidth padding={"0px 30px"}>
          <Container
            flexDirection="column"
            className="group-info"
            width="100%"
            margin="50px 0 20px 0"
          >
            <h1 className="admin-title">Houston (admin dashboard)</h1>
            <div className="filter-bar">
              <h4>FILTER</h4>
              <div className="organisation-dropdown" id="groups-dropdown">
                <p>Group:</p>
                {groups && groups.length > 0 && (
                  <select onChange={handleGroupSwitch} value={selectedGroup}>
                    {groups.map((group, index) => (
                      <option key={index} value={group.groupName}>
                        {group.groupName}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div className="organisation-dropdown">
                <p>Sort users:</p>
                <select onChange={handleSort} value={sortingMethod}>
                  <option value="Alphabetically">Alphabetically</option>
                  <option value="leastProgress">Least Progress</option>
                  <option value="mostProgress">Most Progress</option>
                </select>
              </div>
              <span>
                <Button
                  linkText="Refresh"
                  buttonTheme="orange"
                  onClick={refresh}
                  id="refresh-button"
                />
              </span>
            </div>
          </Container>
          <Container width="100%" className="header-bar">
            <span className="header-tiles">
              <p className="users">Users</p>

              <p className="progress">Engagement</p>
            </span>
            <p className="workshop">Workshop</p>
          </Container>
          <StyledEmailHeader id="email-selected-users">
            <input
              type="checkbox"
              className="checkbox"
              onChange={handleSelectAll}
              checked={emailList.length === sortedGroupUsers?.length}
            />
            <p>Select All</p>
            {emailList.length > 0 && (
              <a className="selected-users" href={`mailto:${emailList.join(";")}`}>
                Email selected users
              </a>
            )}
          </StyledEmailHeader>
          <StyledGroupUserProgress
            display="flex"
            flexDirection="column"
            position="relative"
            width="100%"
            margin="0 0 50px 0"
            id="student-wrapper"
          >
            {loading && <h2>Loading users</h2>}
            {tourRunning && stubbedData && (
              <GroupUserProgress
                key={0}
                user={stubbedData}
                workshops={pageContext.workshops}
                emailList={emailList}
                setEmailList={setEmailList}
                index={0}
                tabIsExpanded={tabIsExpanded}
                setTabIsExpanded={setTabIsExpanded}
              />
            )}
            {!loading &&
              !tourRunning &&
              sortedGroupUsers
                ?.filter(t => !t.isGroupAdmin)
                ?.map((user, index) => (
                  <GroupUserProgress
                    key={index}
                    user={user}
                    workshops={pageContext.workshops}
                    emailList={emailList}
                    setEmailList={setEmailList}
                    index={index}
                    tabIsExpanded={index == 0 && tabIsExpanded}
                    setTabIsExpanded={index == 0 && setTabIsExpanded}
                    lowEngagementScore={data?.sanityHouston?.lowEngagementScore}
                    failedWorkshopHouston={data?.sanityHouston?.failedWorkshopHouston}
                  />
                ))}
          </StyledGroupUserProgress>
        </PageWidth>
      )}
      {!tabletDown && (
        <Joyride
          steps={steps ?? []}
          run={runTour}
          hideCloseButton
          continuous
          locale={{ next: "Got it", last: "Get started" }}
          floaterProps={{ placement: "bottom" }}
          stepIndex={stepIndex}
          styles={{
            buttonNext: { backgroundColor: colors.orange },
            buttonBack: { color: colors.primary },
          }}
          callback={handleJoyrideCallback}
        />
      )}
    </StyledDashboard>
  );
};

export default GroupDashboardTemplate;

const StyledDashboard = styled.div`
  background-color: ${colors.dashboardBackground};
  .group-info {
    h1,
    h2 {
      margin: 0;
    }
  }

  .header {
    background-color: #fff;
    width: 100%;
    padding: 30px;
  }

  .admin-title {
    color: ${colors.maroon};
  }

  .filter-bar {
    background-color: ${colors.progressBackground};
    width: 100%;
    padding: 15px 30px;
    border-radius: 20px;
    margin-top: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0px;
    }

    p {
    }

    select {
      height: 30px;
      margin: auto;
      margin-left: 10px;
      font-size: 18px;
    }

    ${mediaQuery.tabletDown} {
      flex-direction: column;
    }
  }

  .organisation-dropdown {
    display: flex;
  }

  .header-bar {
    p {
      font-size: 14px;
      font-weight: 700;
    }
    .header-tiles {
      display: flex;
      width: 20%;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;

      ${mediaQuery.tabletDown} {
        width: 40%;
      }
    }

    .users {
      width: 30%;
      text-align: left;
      font-size: 20px;
      font-family: "cocon";
    }

    .workshop {
      height: fit-content;
      margin: auto 0px;
      padding-left: 20px;
    }
  }
`;

const StyledGroupUserProgress = styled(Container)`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: visible;
  gap: 10px;
`;

const StyledEmailHeader = styled.div`
  padding: 10px;
  border-bottom: 2px solid #${colors.lightGrey};
  display: flex;
  background-color: #fff;
  padding-left: 20px;

  P {
    font-size: 16px;
    margin: 5px 20px;
  }

  .selected-users {
    color: ${colors.teal};
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    height: fit-content;
    margin: auto 0px;
  }
`;

export const query = graphql`
  query houston {
    sanityHouston {
      onboardingSteps {
        _key
        targetName
        content {
          ...sanityBlockContent
        }
      }
      lowEngagementScore
      failedWorkshopHouston
    }
  }
`;
